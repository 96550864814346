import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import LegalDocuments from "../components/common/legal/LegalDocuments";

import Footer from "../components/sections/footer";

import theme from "@studytools/core/ui/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";

const LegalPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Ako Maps" />
        <Navigation />
        <LegalDocuments />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default LegalPage;
