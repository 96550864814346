import React from "react";
import styled from "styled-components";
import { Wrapper, Container } from "../../global";
import { Typography, Grid } from "@material-ui/core";

const LegalDocuments: React.FC = () => {
  return (
    <LegalWrapper id="legal">
      <LegalContainer>
        <Grid container>
          <GridItem container item alignContent="center" style={{ width: "50%" }}>
            <Typography variant="h3">LEGAL DOCUMENTS</Typography>
          </GridItem>
          <GridItem item>
            <ul>
              <li>
                <Link href="legal/privacy-policy">
                  <Typography variant="body1">Privacy Policy</Typography>
                </Link>
              </li>
              <li>
                <Link href="legal/terms-akomaps">
                  <Typography variant="body1">Ako Maps Trial Period Terms of Use</Typography>
                </Link>
              </li>
            </ul>
          </GridItem>
        </Grid>
      </LegalContainer>
    </LegalWrapper>
  );
};

export default LegalDocuments;

const LegalWrapper = styled(Wrapper)`
  padding-top: 80px;

  @media (max - width: ${props => props.theme.screen.md}) {
    padding-top: 32px;
  }
`;
const LegalContainer = styled(Container)`
  height: calc(100vh - 400px);
  margin: 0 auto;
`;
const GridItem = styled(Grid)`
  width: 50%;
`;
const Link = styled.a`
  color: ${props => props.theme.color.secondary};

  &:visited {
    color: ${props => props.theme.color.secondary};
  }
`;
